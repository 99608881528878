import fetch from 'isomorphic-fetch';
import firebase from 'firebase';

// Set the configuration for your app
// TODO: Replace with your project's config object
var config = {
  apiKey: "AIzaSyBk_7jdI1SUBkPDM7vtx44f7mMoxWjeZ5U",
  authDomain: "sito-personale-d1b84.firebaseapp.com",
  databaseURL: "https://sito-personale-d1b84.firebaseio.com",
};
firebase.initializeApp(config); 


// Get a reference to the database service
var database = firebase.database();

export default function getData() {
  return fetch("https://sito-personale-d1b84.firebaseio.com/sections.json").then(res => res.json());
}

export function readAt(path) {
  return database.ref(path).once('value').then(res => res.val())
}

export function loginWithPopup() {
  var provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('profile');
  provider.addScope('email');

  const date = new Date()

  firebase.auth().signInWithPopup(provider).then(function (result) {
    // This gives you a Google Access Token. You can use it to access the Google API.
    var token = result.credential.accessToken;
    // The signed-in user info.
    var user = result.user;
    // ...

    console.log('authenticated!', result)
  }).then(
    firebase.database().ref('meta/lastUpdate').set(date.toUTCString())
    ).catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
}

export function isLoggedIn() {

  return firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      console.log('user is logged in')
      // User is signed in.
      var displayName = user.displayName;
      var email = user.email;
      var emailVerified = user.emailVerified;
      var photoURL = user.photoURL;
      var isAnonymous = user.isAnonymous;
      var uid = user.uid;
      var providerData = user.providerData;

      return user

    } else {
      console.log('user is not logged in')
      // ...
    }
  });

  
}