import React, {Component} from 'react';
import {render} from 'react-dom';

export default class App extends Component {
  render() {
    const {sectionData} = this.props
    return (
      <section id={sectionData.name}>
        <div className="section-content">
          <small className="section-name">{sectionData.name}</small>
          <div className="section-content-wrapper">
            <h4>{sectionData.subTitle}</h4>
            <div className="section-content-inner">
              <p dangerouslySetInnerHTML={{__html: sectionData.body}}>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

