import React from 'react';
import {render} from 'react-dom';
import App from './App';
import Auth from './components/Auth';
import getData from './database';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {isLoggedIn} from './database'

const Home = () => <App />
const Admin = () => <Auth />

const root = (sections) => (
  <Router>
    <div>
      <Route exact path="/" sections={sections} component={Home} />
      <Route path="/admin" sections={sections} component={Admin} />
    </div>
  </Router>
)

getData().then(sections => {
  render(root(sections), document.getElementById('root'));
});

