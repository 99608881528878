import React, {Component} from 'react';
import {render} from 'react-dom';
import Section from './components/Section'
import Welcome from './components/Welcome'
import {readAt} from './database'
import {hot} from 'react-hot-loader'

class App extends Component {
  state = {
    isLoading: true
  }

  async componentDidMount() {
    const siteData = await readAt('/')

    this.setState({siteData, isLoading: false})
  }
  renderSections = () => {
    const sections = this.state.siteData.sections.map((sectionData, i) => {
      return (
        <Section key={i} sectionData={sectionData} />
      )
    });

    return sections
  }
  render() {
    if(this.state.isLoading) {
      return 'LOADING...'
    }

    return (
      <div id="main-container">
      <small id="site-top">WWW.VALERIOLEO.ME</small>
        <Welcome welcome={this.state.siteData.welcome} />
        {this.renderSections()}
        <div id="site-bottom">
          <a href="https://github.com/valerioleo"><i className="fab fa-github"></i></a>
          <a href="https://twitter.com/valerioleo"><i className="fab fa-twitter"></i></a>
          <a href="https://medium.com/valerioleo"><i className="fab fa-medium"></i></a>
          <a href="mailto:hello@valerioleo.me"><i className="far fa-envelope"></i></a>
        </div>
      </div>
    );
  }
}

export default hot(module)(App)