import React, {Component} from 'react';
import {render} from 'react-dom';
import firebase from 'firebase'

export default class Auth extends Component {
  componentDidMount(){
  }
  render() {
    return <h1>AUTH!</h1>
  }
}
