import React, {Component} from 'react';
import {render} from 'react-dom';
import Section from './Section'
import {readAt} from '../database'

export default class App extends Component {
  render() {
    const {welcome} = this.props
    return (
      <section id="welcome-section">
        <div id="welcome">
          <h1>
            
            {welcome.title}
          </h1>
          <h4>{welcome.subTitle}</h4>
          <img id="satoshi" src="assets/media/satoshi-reverse.png" alt="Fake Satoshi wondering about who is Valerio." />
          <p dangerouslySetInnerHTML={{__html: welcome.body}}></p>
        </div>
      </section>
    );
  }
}
